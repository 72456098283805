import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Button } from 'antd';
import TestingModal from './TestingModal';
import DumpModal from './DumpModal';
import SystemTypeRender from '../SystemTypeRender';
import { useRuntimeConfig } from 'vl-common/src/hooks/Runtime';
import { useSystemType } from 'vl-common/src/context/provider.app';
import useHAR from 'vl-common/src/hooks/useHAR';
import { useRouter } from 'vl-core/useRouter';
import styled from '@emotion/styled';
// @ts-ignore
import logRocketLogo from 'vl-common/assets/img/logRocket.png';
// @ts-ignore
import hbsukLogo from 'vl-common/assets/img/hbsuk_logo.png';
import UIVersionLabel from '../UIVersionLabel';

const StyledFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background-color: #fff;

  border-top: solid 1px var(--productIron);
  padding: 5px 14px;

  position: fixed;
  bottom: 0;
  width: 100%;

  font-size: 14px;
`;

const Footer: React.FC<{ className?: string; logRocketSessionURL?: string; getDynamicConfig: any }> = ({
  className,
  logRocketSessionURL,
  getDynamicConfig
}) => {
  const { LUCY_ENV, DATABASE_VERSION } = useRuntimeConfig();
  const [type] = useSystemType();
  const router = useRouter();
  const isProd = LUCY_ENV.includes('prod');
  const { record, dump, dumpedHAR, clear, inTestingMode, setInTestingMode, inRecordingMode, setInRecordingMode } =
    useHAR(getDynamicConfig);

  const [devToolsIsVisible, setDevToolsIsVisible] = useState(true);
  const [testingModalOpen, setTestingModalOpen] = useState(false);
  const [dumpModalOpen, setDumpModalOpen] = useState(false);

  const systemTypeLabels = {
    admin: '(HBS) client admin',
    referrer: '(client) referrer',
    'referrer-admin': '(client) referrer admin',
    clinician: 'clinician',
    patient: 'patient'
  };
  const systemTypeLabel = systemTypeLabels[type as keyof typeof systemTypeLabels];

  const enterTestingMode = (file: string, userType: string) => {
    setTestingModalOpen(false);
    if (file) {
      sessionStorage.setItem('har', `${userType}/${file}`);
      if (type === 'admin') {
        router.push(`/account/login?har=har/${userType}/${file}`);
      } else {
        router.push(`/login/?har=har/${userType}/${file}`);
      }
      setInTestingMode(true);
    }
  };

  const exitTestingMode = async () => {
    setTestingModalOpen(false);
    setInTestingMode(false);
    sessionStorage.removeItem('har');
    sessionStorage.removeItem('mocked');
    Auth.authenticatedUser = '';
    if (type === 'admin') {
      await window.location.assign('#/account/login');
    } else {
      await window.location.assign('/login');
    }
    record().then();
  };

  return (
    <footer {...{ className }} style={{ height: '60px' }}>
      <StyledFooter>
        <span>
          <UIVersionLabel />
        </span>
        <img
          src={
            //  a (hopefully) temporary measure to accomodate Next’s Webpack image loader
            typeof hbsukLogo === 'string' ? hbsukLogo : hbsukLogo.src
          }
          alt="HBS logo"
          width="40px"
        />{' '}
        <SystemTypeRender APP>
          <a
            rel="noreferrer"
            target="_blank"
            href="/patients/legal/?all_policies=0&activeTab=USAGE"
            tabIndex={0}
            data-testid="terms"
          >
            Terms
          </a>
          <a rel="noreferrer" target="_blank" href="/accessibility" tabIndex={0} data-testid="accessibility">
            Accessibility
          </a>
          <a rel="noreferrer" target="_blank" href="/regulatory" tabIndex={0}>
            <img src="/static/images/ukca.png" alt="UKCA Mark" width="25px" />
          </a>
        </SystemTypeRender>
        <SystemTypeRender ADMIN>
          {!isProd && (
            <span>
              &nbsp;Type: {systemTypeLabel}, &quot;{type}&quot;
            </span>
          )}
        </SystemTypeRender>
        {!isProd && devToolsIsVisible && (
          <>
            Dev Tools:{' '}
            {logRocketSessionURL && (
              <a href={logRocketSessionURL}>
                <img
                  src={
                    // a (hopefully) temporary measure to accomodate Next’s Webpack image loader
                    typeof logRocketLogo === 'string' ? logRocketLogo : logRocketLogo.src
                  }
                  width="30px"
                  alt="LogRocket"
                />
              </a>
            )}
            <div data-testid="db-version">DB Version: {DATABASE_VERSION}</div>
            <Button data-testid="hide-dev-footer" onClick={() => setDevToolsIsVisible(false)}>
              Hide
            </Button>
            <Button
              onClick={() => {
                clear();
              }}
              disabled={inRecordingMode || inTestingMode}
            >
              Clear
            </Button>
            <Button
              onClick={() => {
                if (!inRecordingMode) {
                  record().then(() => {
                    setInRecordingMode(true);
                    sessionStorage.setItem('recording', 'true');
                  });
                }
              }}
              data-testid="record-button"
              disabled={inTestingMode}
              style={inRecordingMode ? { background: 'green', color: 'white' } : {}}
            >
              {!inRecordingMode ? 'Record' : 'Recording...'}
            </Button>
            <Button
              onClick={() => {
                dump();
                setInRecordingMode(false);
                sessionStorage.removeItem('recording');
                setDumpModalOpen(true);
              }}
              disabled={inTestingMode}
            >
              Dump
            </Button>
            <Button
              onClick={() => {
                setTestingModalOpen(true);
              }}
              data-testid="testing-mode-button"
              style={inTestingMode ? { background: '#008001', color: 'white' } : {}}
              disabled={inRecordingMode}
            >
              Testing mode
            </Button>
          </>
        )}
        {!isProd && !devToolsIsVisible && (
          <Button onClick={() => setDevToolsIsVisible(true)} className="dev-tools-item" size="small">
            Show dev tools
          </Button>
        )}
        {/* <SystemTypeRender APP>
          <AccessibilityContainer />
          <div className="section-layout">
            {user?.self_reg_required === false && <AppointmentWait />}
          </div>
        </SystemTypeRender> */}
      </StyledFooter>
      <TestingModal
        visible={testingModalOpen}
        onCancel={() => {
          setTestingModalOpen(false);
        }}
        enterTestingMode={enterTestingMode}
        exitTestingMode={exitTestingMode}
        inTestingMode={inTestingMode}
        setInTestingMode={setInTestingMode}
      />
      <DumpModal
        visible={dumpModalOpen}
        har={dumpedHAR || undefined}
        onCancel={() => {
          setDumpModalOpen(false);
        }}
      />
    </footer>
  );
};

export default Footer;
