import React, { useEffect } from 'react';
import { proxy, useSnapshot } from 'valtio';
import z from 'zod';

export const BooleanCoercionWithDefault = (defaultValue: boolean) =>
  z.union([z.string(), z.number(), z.boolean(), z.null(), z.undefined()]).transform((val) => {
    if (typeof val === 'boolean') return val;

    if (typeof val === 'string') {
      if (val === 'true' || val === '1') return true;
      if (val === 'false' || val === '0') return false;
    }

    if (typeof val === 'number') {
      if (val === 1) return true;
      if (val === 0) return false;
    }

    return defaultValue;
  });

export const ConfigSchema = z.object({
  ADOBE_SDK_KEY: z.string().default(''),
  API_URL: z.string().default(''),
  APP_CLIENT_ID: z.string().default(''),
  APP_CLIENT_ID2: z.string().default(''),
  APP_FLOW: z.string().default(''),
  APP_REGION: z.string().default(''),
  APP_USER_POOL_ID: z.string().default(''),
  APP_VERSION: z.string().default(''),
  AWS_ENDPOINT_NAME: z.string().default(''),
  COGNITO: BooleanCoercionWithDefault(true),
  CONTACT_US_URL: z.string().default(''),
  DATABASE_VERSION: z.string().default(''),
  DISABLED_EXPONENTIAL_BACKOFF: BooleanCoercionWithDefault(false),
  GOOGLE_ANALYTICS_TAG: z.string().default(''),
  GUEST_ACCESS_RESEND: BooleanCoercionWithDefault(false),
  IDENTITY_PROVIDERS: z
    .object({
      domain: z.string().nullable().default(''),
      providers: z
        .object({
          label: z.string().nullable().default(''),
          message: z.string().nullable().default(''),
          provider: z.string().nullable().default('')
        })
        .optional()
        .array()
        .default([]),
      redirectSignIn: z.string().nullable().default(''),
      redirectSignOut: z.string().nullable().default('')
    })
    .nullable()
    .default({}),
  LOG_ROCKET_APP_ID: z.string().nullable().default(''),
  LOG_ROCKET_INPUT_SANITIZER: BooleanCoercionWithDefault(false),
  LOGOUT_ALERT: z.string().nullable().default('0'),
  LUCY_ENV: z.string().default(''),
  MFA_LENGTH: z.string().default(''),
  MFA_SECS: z.string().default(''),
  MFA_VALIDITY_SECS: z.string().default(''),
  NETWORK_LATENCY_WARNING_THRESHOLD: z.string().default(''),
  NETWORK_QUALITY_WARNING_THRESHOLD: z.string().default(''),
  NEXT_PUBLIC_RECAPTCHA_SITE_KEY: z.string().default(''),
  NEXT_PUBLIC_RECAPTCHA_BYPASS_CODE: z.string().default(''),
  NHS_CLIENT: BooleanCoercionWithDefault(false),
  NODE_ENV: z.union([z.literal('development'), z.literal('production')], z.literal('test')).default('development'),
  NOTES_EDITOR: z.string().default(''),
  PVIDEO: z.string().default(''),
  PWA_FEATURE_FLAG: BooleanCoercionWithDefault(false),
  PWA_MINIMUM_IOS_VERSION: z.string().default(''),
  SELF_REFER_CLIENT_ID: z.number().default(0),
  SELF_REG: z.string().default(''),
  SERENOVA_TENANT: z.string().default(''),
  SERVER_LOCALE: z.string().default(''),
  SIGNATURE_PHARMACY_API_URL: z.string().default(''),
  STRIPE_PUBLISHABLE_KEY: z.string().default(''),
  SURVEYJS_LICENSE_KEY: z.string().default(''),
  SYSTEM_MODE: z.string().default(''),
  TEST_RANDOM_FAILURES: z.string().default(''),
  TEST_CLINICIAN_USERNAME: z.string().default(''),
  TEST_CLINICIAN_PASSWORD: z.string().default(''),
  THEME: z.string().default(''),
  TOAST_MESSAGES: z.string().default(''),
  TWILIO_BACKEND_URL: z.string().default(''),
  TWILIO_EVENT_VISIBILITY: BooleanCoercionWithDefault(false),
  TOUR_PROVIDER: z.string().nullable().default(''),
  VAPID_PUBLIC: z.string().default(''),
  DISABLE_WELCOME_POPUP: BooleanCoercionWithDefault(false),
  CLINICIAN_APPOINTMENT_REFRESH_INTERVAL_SECS: z.number().optional(),
  VIDEO_NO_CASTART_AFTER: z.string().default(''),
  VIDEO_START_BUFFER: z.coerce.number().optional(),
  UBRN_ENABLED: BooleanCoercionWithDefault(false),
  PIFU_ENABLED: BooleanCoercionWithDefault(false),
  APPT_SESSION_VERSION: z.union([z.literal(1), z.literal(2)]).default(1),
  MAX_SURVEY_SIZE_MB: z.number().default(18)
});

export type Config = z.infer<typeof ConfigSchema>;

const blankConfig = { LUCY_ENV: '' } as Config;

export const RUNTIME_CONFIG_STATE = proxy({ runtimeConfig: blankConfig });

export function setRuntimeConfig(config: Config) {
  RUNTIME_CONFIG_STATE.runtimeConfig = config;
}

export function useRuntimeConfig() {
  return useSnapshot(RUNTIME_CONFIG_STATE).runtimeConfig;
}

export function getRuntimeConfig() {
  return RUNTIME_CONFIG_STATE.runtimeConfig;
}

const ConfigContext = React.createContext(blankConfig);
export default ConfigContext;
export { ConfigContext };
